import { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useActcastGroup } from '../../hooks/useActcastGroup'
import AddActcastGroupDialog, { type AddActcastGroupDialogProps } from './AddActcastGroupDialog'
import UpdateActcastGroupDialog, { type UpdateActcastGroupDialogProps } from './UpdateActcastGroupDialog'

export default function ActcastGroup() {
  const { state, getActcastGroups, createActcastGroups, updateActcastGroup } = useActcastGroup()
  const [actcastGroupDialogProps, setActcastGroupDialogProps] = useState<Pick<AddActcastGroupDialogProps, 'open'>>({
    open: false
  })
  const [updateActcastGroupDialogProps, setUpdateActcastGroupDialogProps] = useState<
    Pick<UpdateActcastGroupDialogProps, 'open' | '_id' | 'groupId' | 'token' | 'visnuCloudFlag'>
  >({
    open: false,
    _id: '',
    groupId: '',
    token: '',
    visnuCloudFlag: false
  })

  useEffect(() => {
    getActcastGroups()
  }, [getActcastGroups])

  return (
    <Container>
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>groupId</TableCell>
              <TableCell>token</TableCell>
              <TableCell>visnuCloudFlag</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.ids.map((id) => {
              const group = state.actcastGroup[id]
              return (
                <TableRow key={group._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {group._id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {group.groupId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    xxx
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {group.visnuCloudFlag ? 'on' : 'off'}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        setUpdateActcastGroupDialogProps({
                          open: true,
                          _id: group._id,
                          groupId: group.groupId,
                          token: group.token,
                          visnuCloudFlag: group.visnuCloudFlag
                        })
                      }}
                    >
                      編集
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        onClick={() => {
          setActcastGroupDialogProps({
            open: true
          })
        }}
      >
        グループ追加
      </Button>
      <AddActcastGroupDialog
        open={actcastGroupDialogProps.open}
        onClose={() => {
          setActcastGroupDialogProps({
            open: false
          })
        }}
        onSubmit={async ({ groupId, token, visnuCloudFlag }) => {
          await createActcastGroups({ body: { groupId, token, visnuCloudFlag } })
          await getActcastGroups()
        }}
      />
      <UpdateActcastGroupDialog
        open={updateActcastGroupDialogProps.open}
        onClose={() => {
          setUpdateActcastGroupDialogProps({
            open: false,
            _id: '',
            groupId: '',
            token: '',
            visnuCloudFlag: false
          })
        }}
        onSubmit={async ({ token, visnuCloudFlag }) => {
          await updateActcastGroup({
            params: { actcastGroupId: updateActcastGroupDialogProps._id },
            body: { token, visnuCloudFlag }
          })
          await getActcastGroups()
        }}
        _id={updateActcastGroupDialogProps._id}
        groupId={updateActcastGroupDialogProps.groupId}
        token={updateActcastGroupDialogProps.token}
        visnuCloudFlag={updateActcastGroupDialogProps.visnuCloudFlag}
      />
    </Container>
  )
}
