import { useState } from 'react'
import dayjs from 'dayjs'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { type DevicesState } from '../../hooks/useDevices'

type Props = {
  device: DevicesState['devices'][number]
  onRemove: (id: string, name: string) => void
  onEdit: (id: string, name: string) => void
}

export default function DeviceRow(props: Props) {
  const [open, setOpen] = useState(false)
  const device = props.device

  return (
    <>
      <TableRow key={device._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {device._id}
        </TableCell>
        <TableCell component="th" scope="row" sx={{ minWidth: 100 }}>
          {device.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {device.foundness}
        </TableCell>
        <TableCell component="th" scope="row">
          {device.act?.id}
        </TableCell>
        <TableCell component="th" scope="row">
          {device.act?.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {device.takePhotoSettings}
        </TableCell>
        <TableCell component="th" scope="row">
          {device.slackNotificationSettings}
        </TableCell>
        <TableCell component="th" scope="row">
          <UpdatedAt updatedAt={device.updatedAt} />
        </TableCell>
        <TableCell component="th" scope="right">
          <Button onClick={() => props.onEdit(device._id, device.name)}>編集</Button>
          <Button onClick={() => props.onRemove(device._id, device.name)}>削除</Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={9} sx={{ pt: 0, pb: 0 }}>
          <Collapse in={open} timeout="auto">
            <Box sx={{ m: 1 }}>
              <Typography gutterBottom component="div">
                Actcast ID: {device.actcastId}
              </Typography>
              <Typography gutterBottom component="div">
                Tag: [ "{device.tags.join('", "')}" ]
              </Typography>
              <Typography gutterBottom component="div">
                actcastDeviceGroups: {device.actcastDeviceGroups.length === 0 ? '所属なし' : ''}
              </Typography>
              <ActcastDeviceGroupsTable actcastDeviceGroups={device.actcastDeviceGroups} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function UpdatedAt(props: { updatedAt: string }) {
  const theme = useTheme()
  const updatedAt = dayjs(new Date(props.updatedAt))
  const isWarn = dayjs().diff(updatedAt, 'days') > 7

  const sx = isWarn ? { background: theme.palette.error.main } : {}
  return <Box sx={sx}>{updatedAt.format('YYYY/MM/DD HH:mm:ss')}</Box>
}

function ActcastDeviceGroupsTable(props: {
  actcastDeviceGroups: DevicesState['devices'][number]['actcastDeviceGroups']
}) {
  if (props.actcastDeviceGroups.length === 0) {
    return <></>
  }
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>id</TableCell>
          <TableCell>name</TableCell>
          <TableCell>Organization id</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.actcastDeviceGroups.map((e) => {
          return (
            <TableRow key={e._id}>
              <TableCell component="th" scope="row">
                {e._id}
              </TableCell>
              <TableCell component="th" scope="row">
                {e.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {e.organizationId}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
