import { useForm, Form, FieldButton, OutlinedField } from 'client-shared/hooks/useForm/index.js'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

type UpdateActcastGroupForm = {
  token: string
  visnuCloudFlag: 'on' | 'off'
}

export type UpdateActcastGroupDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: (args: { token: string; visnuCloudFlag: boolean }) => Promise<unknown>
  _id: string
  groupId: string
  token: string
  visnuCloudFlag: boolean
}

export default function UpdateActcastGroupDialog(props: UpdateActcastGroupDialogProps) {
  const form = useForm<UpdateActcastGroupForm>({
    defaultValues: {
      token: props.token,
      visnuCloudFlag: props.visnuCloudFlag ? 'on' : 'off'
    }
  })

  const onSubmit = ({ token, visnuCloudFlag }: UpdateActcastGroupForm) => {
    props.onSubmit({ token, visnuCloudFlag: visnuCloudFlag === 'on' }).then(() => props.onClose())
  }

  const visnuCloudFlagOptions: UpdateActcastGroupForm['visnuCloudFlag'][] = ['on', 'off']

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{
        onEnter: () =>
          form.reset({
            token: props.token,
            visnuCloudFlag: props.visnuCloudFlag ? 'on' : 'off'
          })
      }}
    >
      <Form form={form}>
        <DialogTitle>グループ編集 ({ props.groupId })</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="token" label="token" />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField
              type="select"
              name="visnuCloudFlag"
              label="visnuCloudFlag"
              options={visnuCloudFlagOptions}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <FieldButton onClick={props.onClose}>キャンセル</FieldButton>
          <FieldButton variant="contained" onSubmit={onSubmit}>
            編集する
          </FieldButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}